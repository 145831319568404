import { BaseBoxEntity } from "@/entities/base-box.entity";

export class GunBoxEntity extends BaseBoxEntity {
  maxItemPrice!: number;
  minItemPrice!: number;
  type!: string;
  userItemNames!: string[];
  searchType!: string;
  __typename = "GunBox" as const;

  constructor(opts: any) {
    super(opts);
  }
}
