var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":"","id":"box-page"}},[_c('top-app-bar',{attrs:{"title":_vm.pageTitle,"balance":true},scopedSlots:_vm._u([(_vm.box)?{key:"subtitle",fn:function(){return [(_vm.boxType === 'FreeBox')?_c('div',[_vm._v(" от "+_vm._s(_vm.box.level)+" уровня и суммы пополнений Вашего баланса не меньше чем "+_vm._s(_vm.box.level)+" рубля за 72 часа ")]):(_vm.boxType === 'FragmentBox')?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" стоимостью "),_c('fragment-price',{staticClass:"ml-1",attrs:{"fragment-id":_vm.box.fragmentId,"value":_vm.box.price}})],1):_c('div',[_vm._v(" стоимостью "),(_vm.boxType !== 'SnowBox')?_c('price',{staticClass:"font-weight-regular",attrs:{"right":"","icon-color":"inherit","value":_vm.box.price}}):_c('snow-price',{staticClass:"font-weight-regular",attrs:{"right":"","icon-color":"inherit","value":_vm.box.price}})],1)]},proxy:true}:null],null,true)}),(!(_vm.boxType === 'GunBox' && !!_vm.box && _vm.box.type === 'KNIFE_OR_NOTHING'))?_c('scroll-fetch-more',{attrs:{"query":_vm.$apollo.queries.items,"mode":"offset"}}):_vm._e(),(_vm.$apollo.queries.box.loading || !(_vm.items || []).length)?_c('v-skeleton-loader',{attrs:{"elevation":"0","type":"image","id":"load-tape","max-height":"287","min-height":"287","height":"287"}}):_c('box-tape',{ref:"tape",staticClass:"box-tape",attrs:{"items":_vm.mostExpensiveItem && _vm.items
        ? [...(_vm.mostExpensiveItem ? [_vm.mostExpensiveItem] : []), ..._vm.items]
        : _vm.items},scopedSlots:_vm._u([(_vm.boxType === 'AccountBox')?{key:"item",fn:function({ item, active }){return [_c('account',{staticClass:"fit item",class:{ active },attrs:{"value":item}})]}}:null],null,true)},[_c('v-fade-transition',[(!_vm.opening && _vm.openingCount === 0 && _vm.box.image)?_c('div',{staticClass:"box-tape__image"},[_c('v-img',{attrs:{"contain":"","width":"200px","height":"200px","src":_vm.box.image}})],1):_vm._e()]),_c('border-container',{staticClass:"pa-2"},[_c('v-slide-y-reverse-transition',{attrs:{"duration":"50","mode":"out-in"}},[(!_vm.user)?[_c('gradient-btn',{key:1,attrs:{"x-large":"","to":"#login"}},[_vm._v(" Авторизоваться ")])]:(
            _vm.boxType === 'SnowBox' && _vm.user.snowBalance < _vm.box.price && !_vm.opening
          )?[_c('gradient-btn',{attrs:{"x-large":"","to":"/support#getSnowBalance"}},[_vm._v(" Как получить снежинки? ")])]:(
            _vm.boxType === 'FragmentBox' &&
            (!_vm.fragmentBoxesBalance ||
              _vm.fragmentBoxesBalance[_vm.box.fragmentId] < _vm.box.price) &&
            !_vm.opening
          )?[_c('gradient-btn',{attrs:{"x-large":"","to":"/support#getFragments"}},[_vm._v(" Как получить осколки? ")])]:[(!_vm.opening)?_c('gradient-btn',{key:2,attrs:{"disabled":_vm.boxType === 'FreeBox'
                ? _vm.user.levelPoints < _vm.box.levelPoints
                : _vm.boxType === 'SnowBox'
                ? _vm.user.snowBalance < _vm.box.price
                : _vm.boxType === 'FragmentBox'
                ? _vm.fragmentBoxesBalance[_vm.box.fragmentId] < _vm.box.price
                : _vm.boxType === 'GunBox'
                ? _vm.user.balance < _vm.boxPriceWithBonusBalance
                : _vm.user.balance < _vm.box.price,"x-large":""},on:{"click":_vm.open}},[(_vm.boxType !== 'FreeBox')?[_vm._v(" Открыть за "),(_vm.boxType === 'GunBox')?[_c('price',{attrs:{"right":true,"icon-color":"white","value":_vm.boxPriceWithBonusBalance}}),(_vm.boxBonusPrice)?[_vm._v(" + "),_c('bonus-price',{attrs:{"right":true,"icon-color":"white","value":_vm.boxBonusPrice}})]:_vm._e()]:(_vm.boxType === 'FragmentBox')?[_c('fragment-price',{attrs:{"fragment-id":_vm.box.fragmentId,"right":true,"value":_vm.box.price}})]:(_vm.boxType !== 'SnowBox')?_c('price',{attrs:{"right":true,"icon-color":"white","value":_vm.box.price}}):_c('snow-price',{attrs:{"right":"","icon-color":"inherit","value":_vm.box.price}})]:[(_vm.user.levelPoints >= _vm.box.levelPoints)?[_vm._v(" Открыть ")]:[_vm._v(" Необходим "+_vm._s(_vm.box.level)+" уровень")]]],2):_c('gradient-btn',{key:3,attrs:{"loading":_vm.loading,"x-large":""},on:{"click":_vm.skip}},[_vm._v(" Пропустить анимацию ")])]],2)],1)],1),_c('item-list',{staticClass:"mt-6 box-items-container",attrs:{"basic":"","items":_vm.mostExpensiveItem && _vm.items
        ? [...(_vm.mostExpensiveItem ? [_vm.mostExpensiveItem] : []), ..._vm.items]
        : _vm.items,"selectable":false},scopedSlots:_vm._u([(_vm.boxType === 'AccountBox')?{key:"item",fn:function({ item, index }){return [_c('account',{key:index,attrs:{"value":item}})]}}:null,{key:"title",fn:function(){return [_c('div',{staticClass:"mb-2"},[(_vm.itemsCount === null)?_c('v-skeleton-loader',{attrs:{"elevation":"0","type":"text"}}):[_c('div',[_vm._v(" Содержимое кейса "),(_vm.itemsCount)?_c('span',{staticClass:"secondary--text font-weight-regular ml-1 text-font-secondary"},[_vm._v(_vm._s(_vm.itemsCount))]):_vm._e()])]],2)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }