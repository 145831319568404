
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountEntity } from "@/entities/account.entity";
import Price from "@/components/base/Price.vue";
import defaultAccountImage from "@/assets/account-zip.svg";

@Component({
  components: {
    Price,
  },
})
export default class Account extends Vue {
  defaultAccountImage = defaultAccountImage;
  @Prop({ default: 91 / 89 }) aspectRatio!: number;
  @Prop() basic!: boolean;
  @Prop() value!: AccountEntity;
}
