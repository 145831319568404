import gql from "graphql-tag";

export function useFindOne(modelName: string, fragment: any) {
  const fragmentName = fragment.definitions[0].name.value;

  return gql`
        query($filter: MongooseFilterDto${modelName}, $options: MongooseQueryOptionsDto){
            findOne${modelName}(filter: $filter, options: $options){
                ...${fragmentName}
            }
        }
        ${fragment}
    `;
}
